<template>
  <div class="formBox" v-loading="loading">
    <div
      class="loading_po"
      v-loading="loading"
      element-loading-text="请稍后..."
      element-loading-spinner="el-icon-loading"
    ></div>
    <div style="display: flex; align-items: center">
      <h4 style="color: #146aff; padding: 0px 20px 0px 30px">基本信息</h4>
    </div>
    <el-form ref="form" :model="form" label-width="120px" :rules="updatePwdRules">
      <el-form-item label="报告类型:" prop="reportType">
        <el-radio-group v-model="form.reportType">
          <el-radio :label="'单份报告'">单份报告</el-radio>
          <el-radio :label="'报告合集'">报告合集</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="报告图片" prop="pdfImage">
        <div class="cover">
          <div class="img_box">
            <img-big-upload
              :imageShow.sync="form.pdfImage"
              :addTitleShow="false"
              @removeImg="removeImgs"
              @uploadOneImgShow="uploadOneImgs"
            />
            <span style="color: #6ba1ff"
              >(建议整体尺寸为300*160px，图片格式应为jpg、jpeg、png，图片应小于12MB)
            </span>
          </div>
          <div v-if="form.pdfImage">缩略图</div>
          <div v-if="form.pdfImage" class="thumbnail">
            <div class="thumbnail_box">
              <img :src="form.pdfImage" alt="" />
              <div>图片尺寸：300*160px</div>
              <div>图片大小：4MB</div>
              <div>展示位置：报告列表</div>
            </div>
            <div class="thumbnail_box">
              <img :src="form.pdfImage" alt="" />
              <div>图片尺寸：300*160px</div>
              <div>图片大小：5MB</div>
              <div>展示位置：报告详情页</div>
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="报告名称" prop="reportName">
        <!-- <el-input
          @blur="reportNameBlur"
          v-model="form.reportName"
          placeholder="请输入报告名称"
        ></el-input> -->
        <el-autocomplete
          v-model="form.reportName"
          :fetch-suggestions="querySearchAsyncaptName"
          placeholder="请输入报告名称"
          @select="handleSelectcaptName"
        >
          <template slot-scope="{ item }">
            <div class="name">
              <span class="addr" style="position: relative; top: -8px; left: 5px">{{
                item.reportName
              }}</span>
            </div>
          </template>
        </el-autocomplete>
      </el-form-item>
      <el-form-item label="一句话介绍:" prop="reportBrief">
        <div class="txtree">
          <el-input
            v-model="form.reportBrief"
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 4 }"
            placeholder="请输入一句话简介"
            maxlength="100"
            show-word-limit
          />
        </div>
      </el-form-item>
      <el-form-item label="封面图" prop="coverImage">
        <div class="cover">
          <div class="img_box">
            <img-big-upload
              :imageShow.sync="form.coverImage"
              :addTitleShow="false"
              @removeImg="removeImg"
              @uploadOneImgShow="uploadOneImg"
            />
            <span style="color: #6ba1ff">建议上传图片整体尺寸为682*368 px，大小不超过8MB </span>
          </div>
          <div v-if="form.coverImage">缩略图</div>
          <div v-if="form.coverImage" class="thumbnail">
            <div class="thumbnail_box">
              <img style="width: 123px; height: 83px;" :src="form.coverImage" alt="" />
              <div>图片尺寸：682*368 px</div>
              <div>图片大小：4MB</div>
              <div>展示位置：首页</div>
            </div>
            <div class="thumbnail_box">
              <img style="width: 123px; height: 83px;" :src="form.coverImage" alt="" />
              <div>图片尺寸：682*368 px</div>
              <div>图片大小：5MB</div>
              <div>展示位置：首页banner区域</div>
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="网盘链接" prop="networkStorageLink">
        <el-input v-model="form.networkStorageLink" placeholder="请输入网盘链接"></el-input>
      </el-form-item>
      <el-form-item label="提取码" prop="fetchCode">
        <el-input v-model="form.fetchCode" placeholder="请输入提取码"></el-input>
      </el-form-item>
      <el-form-item label="报告篇数" prop="reportNumber">
        <div class="original">
          <el-input-number v-model="form.reportNumber" :min="1" label="描述文字"></el-input-number>
          <span class="online_color">篇</span>
        </div>
      </el-form-item>
      <el-form-item v-if="form.reportType == '单份报告'" label="报告页数" prop="pages">
        <div class="original">
          <el-input-number
            v-model="form.pages"
            :min="1"
            :max="300"
            label="描述文字"
          ></el-input-number>
          <span class="online_color">页</span>
        </div>
      </el-form-item>

      <el-form-item label="出版时间" prop="date">
        <el-date-picker
          style="width: 237.37px"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          v-model="form.releaseTime"
          type="datetime"
          align="right"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="出版机构" prop="releaseMechanism">
        <el-input v-model="form.releaseMechanism" placeholder="请输入发布机构"></el-input>
      </el-form-item>
      <el-form-item v-if="form.reportType == '报告合集'" label="报告合集">
        <div class="general_inputs">
          <div
            class="input_flex"
            v-for="(item, index) in form.reportCollectionResults"
            :key="index"
          >
            <div>
              <el-form-item label="附件名称">
                <div class="valueType_flex">
                  <el-input v-model="item.fileName" placeholder="请输入附件名称"></el-input>
                </div>
              </el-form-item>
              <el-form-item
                :prop="`reportCollectionResults.${index}.releaseTime`"
                label="出版时间"
                :rules="{
                  required: true,
                  message: '请选择时间',
                  trigger: 'change'
                }"
              >
                <div class="valueType_flex">
                  <div class="date_picker">
                    <el-date-picker
                      value-format="yyyy-MM-dd"
                      v-model="item.releaseTime"
                      type="date"
                      placeholder="选择日期"
                    >
                    </el-date-picker>
                  </div>
                </div>
              </el-form-item>
            </div>

            <div style="display: flex; font-size: 24px; margin-left: 20px">
              <span v-if="index == 0" style="color: #4e93fb" @click="addvalueTitle"
                ><i class="el-icon-circle-plus"></i
              ></span>
              <span v-if="index > 0" style="color: #ff7575" @click="delvalueTitle(index)"
                ><i class="el-icon-delete"></i
              ></span>
            </div>
          </div>
        </div>
      </el-form-item>

      <el-form-item v-if="form.reportType == '单份报告'" label="报告附件" prop="reportFile">
        <div style="display: flex">
          <el-input
            v-model="form.reportFile"
            placeholder="请添加服务商关联的附件"
            style="width: 512px"
          />
          <el-upload
            action
            class="upload-demo"
            ref="upload"
            :show-file-list="false"
            :before-upload="beforeUpload"
            :http-request="upload"
          >
            <el-button
              class="el_buttom"
              slot="trigger"
              size="mini"
              type="primary"
              style="height: 40px; margin-left: 15px"
              plain
              >上传附件</el-button
            >
          </el-upload>
        </div>
      </el-form-item>

      <el-form-item label="报告简介" prop="reportIntro">
        <div class="editBarBox">
          <div class="editBar">
            <Editorbar v-model="form.reportIntro" :isClear="isClear" />
            <div class="img_tips" style="margin-top: 30px; width: 700px">
              <span
                >(图片的建议尺寸长为750px，富文本内图片格式应为jpg、jpeg、png，图片应小于12MB)
              </span>
            </div>
          </div>
        </div>
      </el-form-item>

      <h4 class="h4_share">附加信息</h4>

      <el-form-item label="费用类型:" prop="chargeWay">
        <el-radio-group v-model="form.chargeWay" @change="chargeWay">
          <el-radio :label="1">付费</el-radio>
          <el-radio :label="2">免费</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="form.chargeWay == '1'" label="原价" prop="originalPrice">
        <div class="original">
          <el-input v-model="form.originalPrice" placeholder="请输入原价"></el-input>
        </div>
      </el-form-item>
      <div class="add_information" v-if="form.chargeWay == '1'">
        <div style="padding: 20px">普通用户</div>
        <div class="online_conference">
          <el-form-item label="费用类型" prop="">
            <div>
              <el-radio-group v-model="form.userChargeWay" @change="chargeWay">
                <el-radio :label="1">付费</el-radio>
                <el-radio :label="2">免费</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>

          <el-form-item v-if="form.userChargeWay == '1'" label="优惠方式" prop="userDiscountType">
            <div>
              <el-radio-group v-model="form.userDiscountType">
                <el-radio :label="'折扣'">折扣</el-radio>
                <el-radio :label="'优惠价'">优惠价</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item
            v-if="form.userDiscountType == '折扣' && form.userChargeWay == '1'"
            label="折扣"
            prop="discount"
          >
            <div class="original">
              <el-input-number
                v-model="form.userDiscount"
                :min="0"
                :max="100"
                label="描述文字"
              ></el-input-number>
              <span class="online_color">%</span>
            </div>
          </el-form-item>
          <el-form-item
            v-if="form.userDiscountType == '优惠价' && form.userChargeWay == '1'"
            label="优惠价"
            prop="discount"
          >
            <div class="original">
              <el-input v-model="form.userDiscount" placeholder="请输入原价"></el-input>
              <span class="online_color">元</span>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="add_information" v-if="form.chargeWay == '1'">
        <div style="padding: 20px">个人会员用户</div>
        <div class="online_conference">
          <el-form-item label="费用类型" prop="">
            <div>
              <el-radio-group v-model="form.memberChargeWay" @change="chargeWay">
                <el-radio :label="1">付费</el-radio>
                <el-radio :label="2">免费</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>

          <el-form-item v-if="form.memberChargeWay == '1'" label="优惠方式" prop="userDiscountType">
            <div>
              <el-radio-group v-model="form.memberDiscountType">
                <el-radio :label="'折扣'">折扣</el-radio>
                <el-radio :label="'优惠价'">优惠价</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item
            v-if="form.memberDiscountType == '折扣' && form.memberChargeWay == '1'"
            label="折扣"
            prop="discount"
          >
            <div class="original">
              <el-input-number
                v-model="form.memberDiscount"
                :min="0"
                :max="100"
                label="描述文字"
              ></el-input-number>
              <span class="online_color">%</span>
            </div>
          </el-form-item>
          <el-form-item
            v-if="form.memberDiscountType == '优惠价' && form.memberChargeWay == '1'"
            label="优惠价"
            prop="discount"
          >
            <div class="original">
              <el-input v-model="form.memberDiscount" placeholder="请输入原价"></el-input>
              <span class="online_color">元</span>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="add_information" v-if="form.chargeWay == '1'">
        <div style="padding: 20px">企业会员用户</div>
        <div class="online_conference">
          <el-form-item label="费用类型" prop="">
            <div>
              <el-radio-group v-model="form.companyChargeWay" @change="chargeWay">
                <el-radio :label="1">付费</el-radio>
                <el-radio :label="2">免费</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>

          <el-form-item
            v-if="form.companyChargeWay == '1' && form.chargeWay == '1'"
            label="优惠方式"
            prop="userDiscountType"
          >
            <div>
              <el-radio-group v-model="form.companyDiscountType">
                <el-radio :label="'折扣'">折扣</el-radio>
                <el-radio :label="'优惠价'">优惠价</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item v-if="form.companyDiscountType == '折扣'" label="折扣" prop="discount">
            <div class="original">
              <el-input-number
                v-model="form.companyDiscount"
                :min="0"
                :max="100"
                label="描述文字"
              ></el-input-number>
              <span class="online_color">%</span>
            </div>
          </el-form-item>
          <el-form-item
            v-if="form.companyDiscountType == '优惠价' && form.chargeWay == '1'"
            label="优惠价"
            prop="discount"
          >
            <div class="original">
              <el-input v-model="form.companyDiscount" placeholder="请输入原价"></el-input>
              <span class="online_color">元</span>
            </div>
          </el-form-item>
        </div>
      </div>
      <h4 class="h4_share">小程序分享链接信息</h4>
      <el-form-item label="分享链接标语:" prop="">
        <el-input v-model="form.reportShareTitle" placeholder="请输入分享链接标语" />
      </el-form-item>
      <el-form-item label="分享链接图片:" prop="">
        <div class="pictures_box_left">
          <img-big-upload
            :imageShow.sync="form.reportShareImage"
            :addTitleShow="false"
            @removeImg="removeImgDians"
            @uploadOneImgShow="uploadOneImgShowDians"
          />
        </div>
        <div class="img_tips">
          <span>(建议整体尺寸为630*510px，图片格式应为jpg、jpeg、png，图片应小于12MB) </span>
        </div>
      </el-form-item>
    </el-form>
    <!-- <div class="editInformation" v-if="activeId">
      <div class="item">编辑人：{{ form.createId }}</div>
      <div class="item">编辑时间：{{ form.createTime }}</div>
      <div class="item">发送人：{{ form.updateId }}</div>
      <div class="item">发送时间：{{ form.updateTime }}</div>
    </div> -->
    <div class="button_box">
      <div class="save button" @click="clickSave">保存</div>
      <div class="cancel button" @click="clickCancel">取消</div>
    </div>
  </div>
</template>
<script>
import cos from '../../../utils/cos.js'
import {
  apiUpload,
  reportSave,
  reportById,
  reportUpdate,
  checkReportName,
  getSystemReportParam
} from '../../../api/content.js'

import Editorbar from './Editorbar.vue'

export default {
  components: { Editorbar },
  props: {
    activeId: {
      type: String
    }
  },
  data() {
    return {
      loading: false, //loadding
      value: '',
      operatingList: [], //省
      operatingLists: [], //市
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      form: {
        chargeWay: '',
        companyChargeWay: '',
        companyDiscount: 0,
        companyDiscountType: '',
        coverImage: '',
        fetchCode: '',
        id: null,
        isDelete: '',
        memberChargeWay: '',
        memberDiscount: 0,
        memberDiscountType: '',
        networkStorageLink: '',
        originalPrice: 0,
        pages: 0,
        pdfImage: '',
        releaseMechanism: '',
        releaseTime: '',
        reportBrief: '',
        reportCollection: '',
        reportCollectionList: [
          {
            fileName: '',
            releaseTime: ''
          }
        ],
        reportCollectionResults: [
          {
            fileName: '',
            releaseTime: ''
          }
        ],
        reportFile: '',
        reportIntro: '',
        reportName: '',
        reportNumber: 0,
        reportShareImage: '',
        reportShareTitle: '',
        reportType: '',
        userChargeWay: '',
        userDiscount: 0,
        userDiscountType: ''
      },
      checked: false, //通用版
      // logo图片
      imageOneShow: '',
      imageUrlList: [],
      isClear: false,
      updatePwdRules: {
        reportCollectionList: [
          {
            releaseTime: ''
          }
        ],
        reportName: [{ required: true, message: '请输入报告名称', trigger: 'blur' }],
        reportFile: [{ required: true, message: '请上传报告附件', trigger: 'blur' }],
        coverImage: [{ required: true, message: '请上传封面图', trigger: 'blur' }],
        reportBrief: [{ required: true, message: '请输入一句话介绍', trigger: 'blur' }],
        reportType: [{ required: true, message: '请选择报告类型', trigger: 'blur' }]
      },
      issueNameOption: [],
      facilitatorOption: [],
      reportNameOptions: []
    }
  },
  computed: {},
  watch: {},
  created() {
    // this.incity()
    if (this.activeId) {
      this.getReportById()
    }
  },

  mounted() {},
  //方法集合
  methods: {
    // 报告名称
    async querySearchAsyncaptName(queryString, cb) {
      if (this.form.reportName) {
        const { data: res } = await getSystemReportParam({ reportName: this.form.reportName })
        if (res.resultCode == 200) {
          this.reportNameOptions = res.data
          cb(this.reportNameOptions)
        }
      } else {
        // this.form.issueId = null
        cb(this.reportNameOptions)
      }
    },
    async handleSelectcaptName(item) {
      console.log('item', item.reportName)
      this.form.reportName = item.reportName
      if (item.reportName) {
        const { data: res } = await checkReportName({
          reportName: this.form.reportName
        })
        if (res.resultCode == 500) {
          this.$confirm(res.message, '提示', {
            confirmButtonText: '确定',
            type: 'warning'
          }).then(() => {
            this.form.reportName = ''
          })
        }
      }
      for (let key in item) {
        if (!this.activeId) {
          if (item[key] != null && item[key] !== '') {
            this.form[key] = item[key]
            this.form.coverImage = item.coverImages[0]
            this.form.pdfImage = item.pdfImages[0]
          }
        }
      }
    },

    checkParamsData(val) {
      this.form.activityStartTime = val[0]
      this.form.activityEndTime = val[1]
    },
    // 富文本编辑器的内容
    inputVla(val) {
      // console.log(val)
    },
    async getReportById() {
      const { data: res } = await reportById({ id: this.activeId })
      // console.log(res)
      if (res.resultCode == 200) {
        // console.log(res)
        this.form = res.data
        if (this.form.reportCollectionResults == null) {
          this.form.reportCollectionList = [
            {
              fileName: '',
              releaseTime: ''
            }
          ]
        } else {
          this.form.reportCollectionList = this.form.reportCollectionResults
        }

        if (this.form.coverImage == null) {
          this.form.coverImage = ''
        }
        if (!this.form.pdfImage) {
          this.form.pdfImage = ''
        }

        this.form.reportShareImage = this.form.reportShareImage ? this.form.reportShareImage : ''
      }
      // console.log(this.form)
    },
    //当费用类型为免费时
    chargeWay() {
      if (this.form.chargeWay == '2') {
        this.form.originalPrice = 0
        this.form.userDiscountType = ''
      }
      if (this.form.userChargeWay == '2') {
        this.form.userDiscount = 0
      }
      if (this.form.memberChargeWay == '2') {
        this.form.memberDiscount = 0
        this.form.memberDiscountType = ''
      }
      if (this.form.companyChargeWay == '2') {
        this.form.companyDiscount = 0
        this.form.companyDiscountType = ''
      }
    },

    //选择发布机构
    handleSelectcapt(item) {
      this.form.issueId = item.id
      this.form.issueName = item.issueName
    },

    //新增报告合集1
    addvalueTitle() {
      this.form.reportCollectionResults.push({
        fileName: '',
        releaseTime: ''
      })
      this.updatePwdRules.reportCollectionList.push({
        releaseTime: ''
      })
      this.form.reportNumber = this.form.reportCollectionList.length
    },
    //删除报告合集1
    delvalueTitle(index) {
      this.form.reportCollectionResults.splice(index, 1)
      this.updatePwdRules.reportCollectionList.splice(index, 1)
      this.form.reportNumber = this.form.reportCollectionList.length
    },

    //上传pdf
    async beforeUpload(file) {
      this.file = file
      this.filename = file.name
      const extension = file.name.split('.').splice(-1) == 'pdf'
      if (!extension) {
        this.$message.warning('上传模板只能是pdf格式')
        return
      }
      let reader = new FileReader()
      reader.readAsDataURL(file)

      let that = this
      reader.onload = function () {
        that.fileData = reader.result
      }
      return false
    },
    //上传
    upload(params, index) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      /* 直接调用 cos sdk 的方法 */
      cos
        .uploadFile({
          Bucket: 'ginseng-wisdom-1300257084' /* 填写自己的 bucket，必须字段 */,
          Region: 'ap-nanjing' /* 存储桶所在地域，必须字段 */,
          Key:
            'system_img/' +
            params.file.name /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */,
          Body: params.file, // 上传文件对象
          SliceSize:
            1024 *
            1024 *
            500 /* 触发分块上传的阈值，超过5MB 使用分块上传，小于5MB使用简单上传。可自行设置，非必须 */
        })
        .then((res) => {
          if (res.statusCode === 200) {
            this.form.reportFile = 'https://' + res.Location
            loading.close()
            this.$refs.form.clearValidate('reportFile')
          }
        })
        .catch((err) => {
          loading.close()
          // console.log('上传出错', err)
        })
    },

    //获取长地址图片
    uploadOneImgShowDians(val) {
      this.form.reportShareImage = val
    },
    /**
     * 删除图
     */
    removeImgDians(val) {
      this.form.reportShareImage = ''
    },
    // 保存
    clickSave() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let list = []
          this.form.reportCollectionList.forEach((el) => {
            if (el.fileName != '') {
              list.push(JSON.stringify(el))
            }
          })
          this.form.reportCollectionList = list
          if (this.form.id == null) {
            console.log('新增', this.form)
            reportSave(this.form).then((response) => {
              if (response.data.resultCode == 200) {
                this.$message.success('新增成功')
                this.$router.go(-1)
              } else {
                this.$message.error(response.data.message)
              }
            })
          } else {
            this.loading = true
            reportUpdate(this.form).then((response) => {
              if (response.data.resultCode == 200) {
                this.loading = false
                this.$message.success('编辑成功')
                this.$router.go(-1)
              } else {
                this.loading = false
                this.$message.error(response.data.message)
              }
            })
          }
        } else {
          console.log('验证不通过')
        }
      })
    },
    // 取消
    clickCancel() {
      this.$router.push({
        path: '/content/report',
        query: {
          changeNum: true
        }
      })
    },
    // 图片上传路径长地址  logo
    uploadOneImgShow(value) {
      // this.imageOneShow = value
      // this.form.logo = value
    },

    //多个图片上传显示
    uploadOneImg(val) {
      this.form.coverImage = val
      this.$refs.form.clearValidate('coverImage')
    },
    // 移除
    removeImg(val) {
      this.form.coverImage = ''
    },
    //上传报告图片
    uploadOneImgs(val) {
      this.form.pdfImage = val
    },
    // 移除报告图片
    removeImgs(val) {
      this.form.pdfImage = ''
    }
  }
}
</script>
<style scoped lang="less">
::v-deep .original .el-input__inner {
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 180px;
}

::v-deep .online_conference .el-form-item {
  margin-bottom: 10px;
}

.formBox {
  padding-top: 24px;
  width: 100%;
  height: 100%;
  position: relative;
  .loading_po {
    position: absolute;
    bottom: 500px;
    width: 100%;
    z-index: 99999999 !important;
  }
  .img_tips {
    font-size: 12px;
    color: #6ba1ff;
  }
  .h4_share {
    padding: 50px 50px 0px 50px;
    color: #146aff;
  }

  .inputBox {
    height: 40px;
    display: flex;
  }
  .general_inputs {
    width: 625px;
    border: 1px solid #d4d4d4;
    padding: 14px 20px;
    .input_flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 7px 0px;
      .valueType_flex {
        display: flex;
        align-items: center;
        padding-bottom: 10px;
        .volue_name {
          width: 70px;
        }
      }
      span {
        padding-left: 15px;
        font-size: 20px;
        cursor: pointer;
      }
      .el_buttom {
        position: relative;
        left: -110px;
      }
    }
  }

  /deep/.el-input {
    width: 600px;
    height: 40px;
  }

  .labenBox {
    width: 120px;
    display: flex;
    justify-content: flex-end;
    margin-right: 12px;

    .label {
      color: #333333;
      font-size: 14px;
      margin-right: 2px;
    }
    .span {
      color: #f05252;
    }
  }

  .logo_box {
    display: flex;
    .label_box {
      display: flex;
      margin-right: 12px;
      width: 120px;
      justify-content: flex-end;
      .logo_label {
        color: #333333;
        font-size: 14px;
        margin-right: 2px;
      }
      .span {
        color: #f05252;
      }
    }
  }

  .cover {
    display: flex;
    flex-direction: column;
    .thumbnail {
      display: flex;
      color: #bebebe;
    }
    .thumbnail img {
      width: 143.23px;
      height: 81.63px;
      border-radius: 4px;
    }
    .thumbnail video {
      width: 160.28px;
      height: 91.35px;
      border-radius: 4px;
    }

    .thumbnail div {
      margin-right: 20px;
    }
    .thumbnail_box div {
      display: flex;
      flex-direction: column;
      height: 30px;
    }
  }

  .picker {
    width: 240px !important;
  }

  /deep/.el-radio__input {
    width: 18px;
    height: 18px;
  }
  /deep/.el-radio__inner {
    width: 18px;
    height: 18px;
  }
  /deep/.el-radio__label {
    font-size: 14px;
    color: #333333;
  }

  .editInformation {
    margin-top: 40px;
    margin-left: 58px;
    color: #999999;
    font-size: 14px;

    .item {
      margin-bottom: 6px;
    }
  }
  .button_box {
    width: 100%;
    // height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #ececec;
    padding-top: 15px;
    padding-bottom: 55px;
    .send {
      background-color: #f79646;
      color: #fff;
      cursor: pointer;
    }
    .preview {
      background-color: #37cc7e;
      color: #fff;
      cursor: pointer;
    }
    .save {
      background-color: #4e93fb;
      color: #fff;
      cursor: pointer;
    }
    .cancel {
      background-color: #fff;
      color: #4e93fb;
      cursor: pointer;
      border: 1px solid #4e93fb;
    }
    .button {
      width: 112px;
      height: 40px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 16px;
    }
  }
  .editBarBox {
    width: 100%;
    display: flex;

    .editbar_text {
      width: 130px;
      display: flex;
      justify-content: flex-end;
      .text_a {
        margin-right: 2px;
      }
      .span {
        margin-right: 12px;
        color: #f05252;
      }
    }

    .editBar {
      // width: 686px;
      // width: 100%;
      width: 598.31px;
      height: 437px;
    }
  }
}

.valueType_flex {
  /deep/.el-input {
    width: 450px;
    height: 40px;
  }
}

.inline_form_box {
  display: flex;
  // align-items: center;
  ::v-deep .el-input {
    color: #448aff;
    background: #ecf3ff;
    border-color: #b4d0ff;
    border: none;
    width: 217px;
  }
}
.informationType {
  ::v-deep .el-input__inner,
  ::v-deep .el-input {
    width: 154px;
  }
}

::v-deep .el-button--primary.is-plain {
  color: #448aff;
  background: #ecf3ff;
  border-color: #b4d0ff;
  border: none;
}
::v-deep .name_form .el-input__inner {
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 550px;
}
::v-deep .valueType_flex .el-input__inner {
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  // border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 25px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 450px;
}
::v-deep .editor .text {
  border: 1px solid #ccc;
  min-height: 390px;
  max-height: 390px;
}
::v-deep .w-e-text-container .w-e-text {
  max-height: 390px;
}
::v-deep .txtree .el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 600px;
  font-size: inherit;
  color: #606266;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
::v-deep .el-textarea {
  position: relative;
  display: inline-block;
  width: 600px;
  vertical-align: bottom;
  font-size: 14px;
}
::v-deep .original .el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 180px;
}

.h4_share {
  padding: 50px 50px 0px 30px;
  color: #146aff;
}
.add_information {
  width: 433.54px;
  min-height: 100px;
  border: 1px dashed #c4c4c4;
  border-radius: 2px;
  margin-left: 120px;
  margin-bottom: 15px;
  .online_conference {
    .online_color {
      color: #999999;
    }
  }
  .online_conferences {
    margin-top: 16px;
    width: 447.76px;
    height: 305.17px;
    border: 1px dashed #c4c4c4;
    border-radius: 2px;
    margin-left: 120px;
    margin-bottom: 10px;
    .online_color {
      color: #999999;
    }
  }
}
</style>
